import React, { useState } from "react"
import Layout from "../components/layout"
import FormattedDate from "../components/formatteddate"
import SEO from "../components/seo"
import { ArticleConsumer } from "./player-context"
import { useLocation } from "@reach/router"

function NewsDetails(props) {
  var loc = useLocation()
  return (
    <Layout inDetails={true}>
      <SEO
        title={props.info.title}
        image={props.info.image}
        description={props.info.summary}
        article={true}
      ></SEO>
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div
              class={`col-lg-${
                props.hasRelated ? "6" : "12 no-border"
              } col-12 right-col`}
            >
              {" "}
              <button
                className="backButton"
                onClick={() => {
                  if (
                    window.history.length > 1 &&
                    window.history.state == loc.state
                  ) {
                    window.history.back()
                  } else {
                    window.redirect("/")
                  }
                }}
              >
                <i class="fal fa-arrow-right"></i>
                <span>رجوع</span>
              </button>
              <ArticleConsumer>
                {playerContext => (
                  <article className="read-news clearfix">
                    <div className="news-body clearfix">
                      <div className="categories flex-categories">
                        {props.info.source_countryCode.trim() != "" && (
                          <span className="source-country-flag f16">
                            <i
                              className={`flag ${props.info.source_countryCode.toLowerCase()}`}
                            ></i>
                          </span>
                        )}
                        <span className="news-source">
                          <span>{props.info.sourcename}</span>
                        </span>

                        <span className="news-date">
                          <FormattedDate
                            date={props.info.published}
                          ></FormattedDate>
                        </span>
                      </div>
                      <figure>
                        <img
                          referrerPolicy="no-referrer"
                          onError={i => (i.target.style.display = "none")}
                          alt={props.info.title}
                          src={props.info.image}
                          loading="lazy"
                        />
                      </figure>
                      <div className="news-content  clearfix">
                        <h1 className="news-title">{props.info.title}</h1>
                        <div className="one-line news-actions clearfix">
                          {process.env.ENABLE_AUDIO && (
                            <div className="listen floatright">
                              <button
                                onClick={() => play(playerContext, props.info)}
                              >
                                <i
                                  className={
                                    playerContext.state &&
                                    playerContext.state.id == props.info.id
                                      ? "fal fa-stop"
                                      : "fal fa-play"
                                  }
                                />
                                <span>
                                  {" "}
                                  {playerContext.state &&
                                  playerContext.state.id == props.info.id
                                    ? " ايقاف "
                                    : " إستمع "}
                                </span>
                              </button>
                            </div>
                          )}
                          <button
                            className="share floatleft"
                            onClick={() => doShare(props.info)}
                          >
                            <i className="fas fa-share" />
                          </button>
                          <span class="details-category floatleft">
                            {props.info.predicted_category}
                          </span>
                        </div>
                        <div className="news-text">
                          <div className="text-wrapper">
                            {props.text
                              .split(/\.[^\d]/)
                              .map(function (item, index) {
                                if (item == null || item == "") {
                                  return <></>
                                } else {
                                  return (
                                    <span key={index}>
                                      {item}
                                      {item.endsWith(".") ? "" : "."}
                                      <br />
                                      <br />
                                    </span>
                                  )
                                }
                              })}
                          </div>
                        </div>
                        <a
                          href={props.info.link}
                          target="_blank"
                          className="read-news-from-source"
                        >
                          إقرأ الخبر على موقع {props.info.sourcename}
                        </a>
                      </div>
                    </div>
                  </article>
                )}
              </ArticleConsumer>
            </div>
            <div class="col-lg-6 col-12 left-col">
              {props.hasRelated && (
                <section className="layout-grid details-section">
                  <h3 className="section-title">عن الخبر من مصادر اخرى</h3>
                  {props.related.map((item, index) => (
                    <article
                      key={index}
                      className="news-item news-card clearfix"
                    >
                      <div className="item-wrapper clearfix">
                        <div class="one-line top-content clearfix on-masonry">
                          <div class="categories floatright clearfix">
                            {item.source_countryCode.trim() != "" && (
                              <span className="source-country-flag f16">
                                <i
                                  className={`flag ${item.source_countryCode.toLowerCase()}`}
                                ></i>
                              </span>
                            )}
                            <a
                              href={item.link}
                              target="_blank"
                              className="news-source"
                            >
                              {item.sourcename}
                            </a>
                            <span class="news-date floatright">
                              <FormattedDate
                                date={item.published}
                              ></FormattedDate>
                            </span>
                          </div>
                        </div>
                        <div className="news-content">
                          <div class="one-line clearfix on-grid">
                            <div className="categories floatright clearfix">
                              {item.source_countryCode.trim() != "" && (
                                <span className="source-country-flag f16">
                                  <i
                                    className={`flag ${item.source_countryCode.toLowerCase()}`}
                                  ></i>
                                </span>
                              )}
                              <a
                                href={item.link}
                                target="_blank"
                                className="news-source"
                              >
                                {item.sourcename}
                              </a>
                            </div>
                            <div className="news-date floatleft">
                              <FormattedDate
                                date={item.published}
                              ></FormattedDate>
                            </div>
                          </div>
                          <a
                            className="news-title"
                            href={item.link}
                            target="_blank"
                          >
                            {item.title}
                          </a>

                          <div className="one-line news-actions clearfix floatright">
                            <div className="share-and-related floatleft clearfix">
                              <button
                                className="share floatleft"
                                onClick={() => doShare(item)}
                              >
                                <i className="fas fa-share" />
                              </button>
                              <span class="related-news floatleft">
                                <span>{item.predicted_category}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  ))}
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export function play(playerContext, item) {
  playerContext.setPlayAll(false)
  playerContext.setPlaying(true)
  playerContext.setPlayed(0)
  if (playerContext.state && playerContext.state.id == item.id) {
    playerContext.setCurrentPlaying(null)
  } else {
    playerContext.setCurrentPlaying(item)
  }
}

export function doShare(info) {
  if (info) {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.text = `doShare('${info.title}',"/article/${info.id}")`
    document.body.appendChild(script)
  }
}
export default NewsDetails
