import React from "react"
import { graphql } from "gatsby"
import NewsDetails from "../components/newsdetails"

export default function NewsDetailsTemplate({ data }) {
  const noChilds =
    data.nshra.byId.related == null || data.nshra.byId.related == 0
  return (
    <NewsDetails
      info={data.nshra.byId.info}
      related={data.nshra.byId.related}
      hasRelated={!noChilds}
      text={data.nshra.byId.text}
    ></NewsDetails>
  )
}

export const query = graphql`
  query($id: String!) {
    nshra {
      byId(id: $id) {
        info {
          id
          image
          link
          published
          predicted_category
          sourcename
          source_countryCode
          title
        }
        related {
          id
          image
          link
          predicted_category
          published
          sourcename
          source_countryCode
          title
        }
        text
      }
    }
  }
`
